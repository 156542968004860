<template>
  <div class="all">
    <img src="../assets/logo.png" alt="" class="img mr-5" @click="returnToLogin"/>
    <div class="box">
  
        <p class="title">Reset password</p>
        <p class="hint mt-2">Enter your new password</p>
          <b-form-input
            v-model="form.password"
            type="password"
            placeholder="Password"
            class="shape password"
          ></b-form-input>
         <br>
          <b-form-input
            v-model="form.confirmPassword"
            type="password"
            placeholder="Confirm password"
                class="shape password"
          ></b-form-input>


      <b-button
          @click="Reset()" :disabled="disabledProperty"
          class="botton"
          >Reset</b-button
        >

    </div>
                <p class="address"> © {{year}} Altosio Software, Atelierstraße, 81671 Munich, Germany</p>
  </div>
</template>

<script>
import api from "../api/index";

export default {
  name: "Register",
  data() {
    return {
      form: {
        password: "",
        confirmPassword: "",
        token: "",
        email: ""
      },
      year: new Date().getFullYear(),  
    };
  },
  computed: {
    disabledProperty() {
      if (
        this.form.password === "" ||
        this.form.confirmPassword === "" || this.form.password.length <6 || this.form.confirmPassword.length<6 ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
     makeToast(variant, title, textToShow) {
        this.$bvToast.toast(textToShow, {
          title: title,
          variant: variant,
          toaster: 'b-toaster-top-center',
          solid: false
        })
    },
    validateInput() {
      var message = "";
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
      if (this.form.password.match(regex) === null) {
        message ="Password must be between 6 and 20 characters and must contain upper and lower case letters and at least one numeric digit.";
      }
      else if( this.form.password!=  this.form.confirmPassword){
        message = "Passwords do not match."
      }
      return message;
    },
    returnToLogin(){
           this.$router.push({ path: "/" });
    },
    Reset(){
      var token = this.$route.params.token;
      token = token.replace('/', '9dFy1');
      this.form.token = token;
      this.form.email = this.$route.params.email;
      const validationMessage = this.validateInput();
      if(validationMessage===''){
        api.resetPassword(this.form).then(
        () => {
          this.makeToast("success", "Password changed.", "Password changed successfully");
          this.$router.push({ path: "/" });
        },
        (error) => {
          const message = error.responseData;
          this.makeToast("danger", "Oops, something went wrong.", message);
        }
      );
      }
      else{
        this.makeToast("danger", "Oops, something went wrong.", validationMessage);
      }
      
    },
    login() {
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
<style scoped>
.all {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #f2f6ff;
  text-align: center;
}
.img {
  margin: auto;
  cursor: pointer;
  width: 253px;
  height: 75px;
  margin-top: 80px;
}
.box {
  margin: auto;
  width: 600px;
  height: 400px;
  margin-top: 35px;

  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 4px 4px 15px rgba(225, 225, 225, 0.5);
}
.address {
font-family: Roboto-Regular,Helvetica,Arial,sans-serif;
color: rgba(0,0,0,0.54);
font-size: 11px;
line-height: 18px;
padding-top: 12px;
text-align: center;
}
.title {
  margin: auto;
  margin-top: 50px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #23438e;
}
.rowmargin{
  margin-left: 350px;
}
.hint {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #859ec0;
}
.shape {
  margin: auto;
  width: 472px;
  height: 56px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background-repeat: no-repeat;
  text-indent: 40px;
}
.profile {
  background-image: url("../assets/profile.png");
  background-position: 15px 15px;
}

.email {
  background-image: url("../assets/Vector.png");
  background-position: 15px 18px;
}
.password {
  background-image: url("../assets/lock.png");
  background-position: 15px 16px;
}
.company {
  background-image: url("../assets/company.png");
  background-position: 15px 16px;
}
.country {
  background-image: url("../assets/country.png");
  background-position: 15px 16px;
}
.botton {
  margin: auto;
  margin-top: 50px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.reset {
  margin-right: 75px;
  margin-top: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: right;

  color: #23438e;
  cursor: pointer;
}
.signin {
  font-weight: 600;
  color: #23438e;
  cursor: pointer;
}
</style>
